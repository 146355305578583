import React from "react";
const LimeFlightWebsiteSection3 = (props) => {
    return(
        <section className="section-3">
            <div className="container">
                <div className="title-block">
                    <h2 className="h1 section-title extra-spacing">{props.mainTitle}</h2>
                </div>
                <div className="image-block">
                    <img
                        src={props.image1x?.sourceUrl}
                        srcSet={props.image2x?.sourceUrl + " 2x, " + props.image1x?.sourceUrl + " 1x"}
                        width={props.image1x?.width}
                        alt={props.image1x?.altText}
                    />
                </div>
            </div>
        </section>
    )
}

export default LimeFlightWebsiteSection3;